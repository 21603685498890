<template>
  <div class="SloganContainer">
    <div class="title-container">
      <h3 class="title">המשרד מעניק</h3>
      <div class="vertical-line"></div>
    </div>
    <div class="sloganDescription">
        <h3>שירותי ראיית חשבון, יעוץ וליווי פיננסי, גיוס אשראי לעסקים והחזרי מס לשכירים</h3>
    </div>
    <div class="twoImagesContainer">
      <div class="circle circle1"></div>
      <div class="circle circle2"></div>
      <div class="img img1"></div>
      <div class="img img2"></div>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped>
.SloganContainer {
  /* background: rebeccapurple; */
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  z-index: 1;
  margin-top: 16px;
}

.title {
  color: #2196f3;
  font-weight: 300;
}

.vertical-line {
  height: 32px;
  width: 3px;
  background: #2196f3;
  margin-right: 32px;
  margin-left: 16px;
  border-radius: 2px;
}

.sloganDescription {
    margin: 16px 32px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: right;
}

.sloganDescription h3 {
    color: #616161;
    color: #ffffff;
    width: 60%;
}

.twoImagesContainer {
  height: 300px;
  width: 280px;
  position: relative;
}

.img {
  height: 250px;
  width: 120px;
  border-radius: 20px;
  opacity: 0.85;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.59);
}

.img1 {
  position: absolute;
  background: gray;
  top: 0;
  left: 0;
  background-image: url("../../assets/slogan1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.img2 {
  position: absolute;
  background: gray;
  bottom: 0;
  right: 0;
  background-image: url("../../assets/slogan2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.circle {
  width: 180px;
  height: 180px;
  border: 1px solid #2196f3;
  border-radius: 50%;
}

.circle1 {
  position: absolute;
  top: 60px;
  left: 50px;
}

.circle2 {
  position: absolute;
  top: 63px;
  left: 53px;
}

.desktopIcon {
  width: 170px;
  height: 170px;
  position: absolute;
  bottom: 0px;
  left: 32px;
  background-image: url("../../assets/desktop1.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
