<template>
  <div class="ServiceItemContainer">
    <div class="case" ></div>
    <div class="caseTriangle"></div>
    <div class="caseTitle">
      <h3>{{ title }}</h3>
    </div>
    <div class="caseContent">
      <h3>{{ content }}</h3>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: String,
  content: String,
  color: String
});

const { title, content, color } = props;

</script>

<style scoped>
.ServiceItemContainer {
  width: 100%;
  height: 100%;
  padding: 0 32px;
  position: relative;
}

.case {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  /* background-color: #708090; */ 
  background-color: v-bind('color');
  border-radius: 6px 0 6px 6px;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.59);
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.case:before {
  content: "";
  width: 50%;
  height: 20px;
  border-radius: 0px 20px 0 0;
  background-color: inherit;
  position: absolute;
  top: -19px;
  right: 0px;
}

.caseTriangle {
  width: 30px;
  height: 20px;
  border-bottom: 20px solid ;
  border-bottom-color:  v-bind('color');
  border-left: 30px solid transparent;
  position: absolute;
  top: -19px;
  right: 50%;
}

.caseTitle {
  color: #616161;
  position: absolute;
  top: -4px;
  right: 64px;
  font-size: 14px;
}

.caseContent {
  color: white;
  position: absolute;
  width: 80%;
  top: 40px;
  right: 48px;
  font-size: 12px;
  text-align: right;
}
</style>
