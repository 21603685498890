
<template>
  <div class="card">
    <div class="content">
      <h3>{{ content }}</h3>
    </div>
    <div class="icon">
      <i :class="icon" style="color: white"></i>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    icon: String,
  content: String
});

const { content, icon } = props;

</script>

<style scoped>
.card {
  width: 60%;
  height: 50px;
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.icon {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2196f3;
  border-radius: 50%;
  margin: 0 0 0 16px;
}

.circle {
    width: 15px;
    height: 15px;
    background: #2196f3;
    border-radius: 50%;
}

.content {
  flex-grow: 1;
  height: 50px;
  border-radius: 10px 0 0 10px;
  padding: 0 0px;
  display: flex;
  align-items: center;
  justify-content: end;
}

h3 {
  font-size: 14px;
  color: #616161;
  color: #ffffff;
  font-weight: 300;
  text-align: right;
}
</style>