<script setup>
const goToMoreInfo = () => {
  const scrollTo = window.innerWidth < 800 ? 6610 : 3400;
  // window.scrollTo(0, scrollTo);
  window.scrollTo({
    top: scrollTo,
    left: 0,
    behavior: "smooth"
  });
};

// const downloadApp = () => {
//   console.log("downloadApp");
// };
</script>

<template>
  <div class="GoToMoreInfoContainer">
    <!-- <v-btn
      variant="text"
      height="32"
      size="x-small"
      prepend-icon="mdi-chevron-left"
      color="purple"
      @click="goToMoreInfo"
    >מידע נוסף</v-btn> -->
    <v-btn
      color="purple"
      rounded="xl"
      elevation="4"
      size="small"
      class="download-app-btn"
      padding="0 12px"
      @click="goToMoreInfo"
    >
     יצירת קשר
    </v-btn>
  </div>
</template>

<style scoped>
.GoToMoreInfoContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 8px 16px;
  height: 40px;
}

.download-app-btn {
  margin-left: 8px;
}

.download-app-link {
  text-decoration: none;
  color: #ffffff;
}
</style>