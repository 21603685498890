<template>
  <div class="cube">
    <div class="face face__top"></div>
    <div class="face face__left"></div>
    <div class="face face__front"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  size: String,
  color1: String,
  color2: String,
  color3: String
});

const { size, color1, color2, color3 } = props;
</script>

<style scoped>
.cube {
  width: 60px;
  height: 60px;
  
  position: absolute;
  transform-style: preserve-3d;
  transform: rotateX(240deg) rotateZ(-135deg);
}

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.face__top {
  background: v-bind('color3');
  transform: translateZ(-60px);
}

.face__front {
  background: v-bind('color2');
  transform: rotateX(90deg);
  transform-origin: bottom;
}

.face__left {
  background: v-bind('color1');
  transform: translateZ(-60px) rotateY(90deg);
  transform-origin: right;
  width: 60px;
}
</style>
